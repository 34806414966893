import React from "react";
import { Button } from "./components/ui";

//styles
import "./App.css";

export default class App extends React.Component {
  render() {
    return (
      <div className="ui">
        <Button />
      </div>
    );
  }
}
