import { CopyText, OpenLink } from "./button";

// render all the buttons to one component
export function Button() {
  return (
    <>
      <OpenLink
        title="WhatsApp"
        classTitle="whatsapp"
        icon="fab fa-whatsapp"
        url="https://web.whatsapp.com"
      />
      <OpenLink
        title="WhatsApp Chat"
        classTitle="whatsapp"
        icon="fab fa-whatsapp"
        url="https://bakesbyish.com/whatsapp"
      />
      <OpenLink
        title="Web"
        classTitle="web"
        icon="fab fa-firefox"
        url="https://bakesbyish.com"
      />
      <OpenLink
        title=" කූඹියෝ Delivery"
        classTitle="koombiyo"
        icon="fas fa-map"
        url="https://www.koombiyodelivery.lk/"
      />
      <OpenLink
        title="Facebook"
        classTitle="facebook"
        icon="fab fa-facebook"
        url="https://facebook.com"
      />
      <OpenLink
        title="Facebook Group"
        classTitle="facebook"
        icon="fab fa-facebook"
        url="https://facebook.com/groups/bakesbyish"
      />
      <OpenLink
        title="Facebook Page"
        classTitle="facebook"
        icon="fab fa-facebook"
        url="https://facebook.com/bakesbyishani"
      />
      <OpenLink
        title="Instagram"
        classTitle="instagram"
        icon="fab fa-instagram-square"
        url="https://instagram.com"
      />
      <OpenLink
        title="Youtube Music"
        classTitle="music"
        icon="fas fa-record-vinyl"
        url="https://music.youtube.com"
      />
      <OpenLink
        title="Google"
        classTitle="google"
        icon="fab fa-google"
        url="https://google.com"
      />
      <OpenLink
        title="Adobe Express"
        classTitle="editor"
        icon="far fa-image"
        url="https://express.adobe.com"
      />
      <CopyText
        title="Share Facebook Reviews"
        classTitle="facebook"
        icon="fab fa-facebook"
        message="Facebook share URL copied to clipboard"
        text="Vinuka Thejana"
      />
      <CopyText
        title="Share Google Reviews"
        classTitle="google-share"
        icon="fas fa-user-tie"
        message="Google Reviews URL copied to the clipboard"
        text="https://g.page/r/CSkb66HBeuGmEAg/review"
      />
      <CopyText
        title="Share Directions"
        classTitle="google-maps"
        icon="fas fa-map"
        message="Google Maps dirrections copied to clipboard"
        text="https://g.page/bakesbyish?share"
      />
      <CopyText
        title="Copy Facebook link"
        classTitle="facebook"
        icon="fab fa-facebook"
        message="Facebook Profile Link copied to clipboard"
        text="https://fb.me/bakesbyishani"
      />
      <CopyText
        title="Copy Instagram Link"
        classTitle="instagram"
        icon="fab fa-instagram-square"
        message="Instagram URL Copied to the Clipboard"
        text="https://ig.me/bakes_by_ish"
      />
      <CopyText
        title="Copy WhatsApp Link"
        classTitle="whatsapp"
        icon="fab fa-whatsapp"
        message="Whatsapp link copied to the clipboard"
        text="https://wa.link/bakesbyish"
      />
      <CopyText
        title="Copy the WebSite Link"
        classTitle="web"
        icon="fab fa-firefox"
        message="WebSite link copied to the clipboard"
        text="https://bakesbyish.com"
      />
    </>
  );
}
