// open the given link when this button is pressed
export function OpenLink({ title, classTitle, icon, url }) {
  return (
    <button
      className={`btn ${classTitle}`}
      onClick={() => {
        window.open(url);
      }}
    >
      <i className={icon}></i>
      {` ${title}`}
    </button>
  );
}

// copy the given text to the Clipboard
export function CopyText({ title, classTitle, icon, message, text }) {
  return (
    <button
      className={`btn ${classTitle}`}
      onClick={() => {
        navigator.clipboard.writeText(text);
        alert(message);
      }}
    >
      <i className={icon}></i>
      {` ${title}`}
    </button>
  );
}
